<div class="h-screen flex flex-col justify-center items-center">
  <div class="mb-xs">
    <h1 class="text-center">
      @if(redirect()) { Redirecting… } @else { Progressive Loss Viewer Login }
    </h1>
  </div>

  <div class="min-h-lg">
    <mat-card class="w-40 overflow-hidden">
      @if (redirect()) {
      <cla-redirect-prompt data-pgr-id="promptError"> </cla-redirect-prompt> }
      @else if (errorMessage()) {
      <cla-error-prompt
        data-pgr-id="promptError"
        [errorMessage]="errorMessage()"
        (onClose)="clearError()"
      ></cla-error-prompt>
      } @else if (!showSsoLogin()) {
      <cla-login-prompt
        data-pgr-id="promptLogin"
        (onCredentialLogin)="handleCredentialAuth()"
        (onSsoLogin)="showSSOPrompt()"
      ></cla-login-prompt>
      } @else {
      <cla-sso-prompt
        data-pgr-id="promptSso"
        (onCancel)="hideSSOPrompt()"
        (onSubmit)="handleSSOAuth($event)"
      ></cla-sso-prompt>
      }
    </mat-card>
  </div>
</div>
