import { Component, signal } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { AuthenticationService } from "@modules/core";
import { IdpService } from "@modules/core/services/authentication/idp.service";

@Component({
  selector: "cla-login-page",
  templateUrl: "./login-page.component.html",
  styleUrl: "./login-page.component.scss",
})
export class LoginPageComponent {
  readonly errorMessage = signal<string | null>(null);
  readonly showSsoLogin = signal<boolean>(false);
  readonly email = signal<string | null>(null);
  readonly redirect = signal<boolean>(false);

  constructor(
    private readonly authService: AuthenticationService,
    private readonly idpService: IdpService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router
  ) {
    if (!this.authService.isTokenCookieExpired) {
      this.router.navigateByUrl("/welcome");
    }

    const queryParams: Params = this.activatedRoute.snapshot.queryParams;
    if (queryParams?.client) {
      this.redirect.set(true);
      const redirectClient: string = queryParams["client"];
      this.authService.authorize(redirectClient);
    }
  }

  handleCredentialAuth() {
    this.authService.authorize();
  }

  handleSSOAuth(email: string) {
    const client: string | null = this.idpService.getClientFromEmail(email);
    if (!client) {
      this.errorMessage.set("SSO is not available at this time.");
      return;
    }

    this.authService.authorize(client);
  }

  showSSOPrompt() {
    this.showSsoLogin.set(true);
  }

  hideSSOPrompt() {
    this.showSsoLogin.set(false);
  }

  displayError(message: string) {
    this.errorMessage.set(message);
  }

  clearError() {
    this.errorMessage.set(null);
  }
}
