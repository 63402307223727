import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "@modules/shared";
import { ErrorPromptComponent } from "./components/error-prompt/error-prompt.component";
import { LoginPageComponent } from "./components/login-page/login-page.component";
import { LoginPromptComponent } from "./components/login-prompt/login-prompt.component";
import { RedirectPromptComponent } from "./components/redirect-prompt/redirect-prompt.component";
import { SsoPromptComponent } from "./components/sso-prompt/sso-prompt.component";
import { LoginRoutingModule } from "./login-routing.module";

@NgModule({
  declarations: [
    LoginPageComponent,
    LoginPromptComponent,
    SsoPromptComponent,
    ErrorPromptComponent,
    RedirectPromptComponent,
  ],
  imports: [LoginRoutingModule, CommonModule, SharedModule],
})
export class LoginModule {}
