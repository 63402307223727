import { Component, output, OutputEmitterRef } from "@angular/core";

@Component({
  selector: "cla-login-prompt",
  templateUrl: "./login-prompt.component.html",
  styleUrl: "./login-prompt.component.scss",
})
export class LoginPromptComponent {
  readonly onCredentialLogin: OutputEmitterRef<void> = output<void>();
  readonly onSsoLogin: OutputEmitterRef<void> = output<void>();

  credentialLoginSelected(): void {
    this.onCredentialLogin.emit();
  }

  ssoLoginSelected(): void {
    this.onSsoLogin.emit();
  }
}
