import { Component, output, OutputEmitterRef } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";

@Component({
  selector: "cla-sso-prompt",
  templateUrl: "./sso-prompt.component.html",
  styleUrl: "./sso-prompt.component.scss",
})
export class SsoPromptComponent {
  readonly onCancel: OutputEmitterRef<void> = output<void>();
  readonly onSubmit: OutputEmitterRef<string> = output<string>();

  showEmailField: boolean = false;

  public emailControl: FormControl = new FormControl("", [
    Validators.required,
    Validators.email,
  ]);

  handleCancel(): void {
    this.onCancel.emit();
  }

  handleSubmit() {
    if (this.email && this.isValidEmail) {
      this.onSubmit.emit(this.email);
    }
  }

  get isValidEmail(): boolean | undefined {
    return this.emailControl?.valid;
  }

  get email(): string | undefined {
    return this.emailControl?.value;
  }
}
