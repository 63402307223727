<div class="flex flex-col space-y-xxs">
  <button
    mat-flat-button
    class="dialog-confirm"
    color="primary"
    (click)="credentialLoginSelected()"
    data-pgr-id="btnGoToCredentialLogin"
  >
    Login with Credentials
  </button>

  <button
    mat-flat-button
    class="dialog-confirm"
    color="primary"
    (click)="ssoLoginSelected()"
    data-pgr-id="btnGoToSsoLogin"
  >
    Login with SSO
  </button>
</div>
